import * as React from 'react';
import { useStoreon } from 'hooks';
import TimeOrangeIcon from './icons/timeOrange.svg';
import EditIcon from './icons/Edit';
import { formatTimeDelivery, maxTime } from '../utils';

export const CheckoutDeliveryTime = () => {
  const { dispatch, deliveryAt: selectedDeliveryAt, appData } = useStoreon('deliveryAt', 'appData');
  const nearestDelivery = appData.viewer.nearestDeliveryInterval?.value || null;

  const deliveryAt = maxTime(selectedDeliveryAt, nearestDelivery);
  const [day, prevTime, nextTime] = formatTimeDelivery(deliveryAt);

  const change = () => dispatch('setModal', { type: 'deliveryTimePicker' });

  return (
    <div
      className="flex justify-between items-center border-b py-4 relative"
      style={{ borderColor: 'hsla(0,0%,59.2%,.2)' }}
    >
      <div className="flex items-center">
        <div className="flex justify-center items-center w-12 h-12 bg-orange-100 rounded-full mr-5 lg:w-9 lg:h-9 lg:mr-3.5">
          <img src={TimeOrangeIcon} alt="clock" className="w-6 h-6 lg:h-4 lg:w-4" />
        </div>
        <div>
          <p className="text-gray-50 lg:text-xs lg:py-0.5">Время доставки</p>
          <p className="lg:text-sm lg:py-0.5">
            {day} c {prevTime} до {nextTime}
          </p>
        </div>
      </div>
      <button
        type="submit"
        onClick={change}
        className="flex justify-center items-center bg-red-500 h-8 rounded-2xl px-5 text-sm uppercase text-black font-medium tracking-wider duration-200 hover:opacity-70 lg:px-0 lg:w-8"
      >
        <span className="lg:hidden">изменить</span>
        <div className="lg:block hidden">
          <EditIcon size={25} />
        </div>
      </button>
      <button type="submit" aria-label="label" onClick={change} className="hidden absolute w-full h-full lg:block" />
    </div>
  );
};
