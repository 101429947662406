import * as React from 'react';
import { Link } from 'gatsby';
import { formatPhone } from 'utils';
import { useStoreon } from 'hooks';
import UserOrangeIcon from './icons/userOrange.svg';
import EditIcon from './icons/Edit';

export const CheckoutChangeProfile = () => {
  const { user } = useStoreon('user');

  return (
    <div
      className="flex justify-between items-center border-b py-4 relative"
      style={{ borderColor: 'hsla(0,0%,59.2%,.2)' }}
    >
      <div className="flex items-center justify-between">
        <div className="flex justify-center items-center w-12 h-12 bg-orange-100 rounded-full mr-5 lg:w-9 lg:h-9 lg:mr-3.5">
          <img src={UserOrangeIcon} alt="clock" className="w-6 h-6 lg:h-4 lg:w-4" />
        </div>

        <div>
          <p className="text-gray-50 lg:text-xs lg:py-0.5 duration-200">Профиль</p>
          <p className="lg:text-sm lg:py-0.5">{user?.name ? user.name : 'Я'}</p>
          <p className="lg:text-sm lg:py-0.5">{user?.mainPhone ? formatPhone(user?.mainPhone) : ''}</p>
        </div>
      </div>
      <Link
        to="/my/settings"
        className="flex flex-none justify-center items-center bg-red-500 h-8 rounded-2xl px-5 text-sm uppercase text-black font-medium tracking-wider duration-200 hover:opacity-70 lg:px-0 lg:w-8"
      >
        <span className="lg:hidden">изменить</span>
        <div className="lg:block hidden">
          <EditIcon size={25} />
        </div>
      </Link>
      <Link to="/my/settings" className="absolute left-0 top-0 w-full h-full hidden z-50 lg:block" />
    </div>
  );
};
