import * as React from 'react';
import WeightIcon from './icons/Weight';

type Type = 'ERROR' | 'WARNING' | 'SUCCESS' | 'WEIGHT';
export type Props = {
  readonly type: Type;
  readonly message: string;
};

// TODO: add normal icons
const icons: { [key in Type]: React.ReactNode } = {
  WEIGHT: <WeightIcon className="flex-shrink-0" />,
  ERROR: <WeightIcon className="flex-shrink-0" />,
  WARNING: <WeightIcon className="flex-shrink-0" />,
  SUCCESS: <WeightIcon className="flex-shrink-0" />,
};

export const Alert = ({ type, message }: Props) => (
  <div className="mt-7 flex items-center" title={type}>
    {icons[type]}
    <p className="ml-3">{message}</p>
  </div>
);
