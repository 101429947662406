import * as React from 'react';

const stage = [
  { stageStep: 1, title: 'КОРЗИНА' },
  { stageStep: 2, title: 'ПОДТВЕРЖДЕНИЕ' },
  { stageStep: 3, title: 'ОФОРМЛЕН' },
  { stageStep: 4, title: 'ОПЛАТА' },
];

export const CheckoutStage = ({ step }: { step: number }) => (
  <div className="relative border-b border-red-300 mb-4 mt-4 lg:hidden">
    {stage.map((s) => (
      <div className="relative inline-block pb-3 mr-10" key={s.title}>
        <p
          className={`uppercase tracking-wider ${step === s.stageStep ? 'text-orange-50' : 'text-red-400'}`}
          style={{ fontSize: '11px' }}
        >
          {s.title}
        </p>
        <div
          className={`absolute left-0 right-0 mx-auto text-center h-2.5 w-2.5 rounded-full ${
            step === s.stageStep ? 'bg-orange-50' : 'bg-red-400'
          }`}
          style={{ bottom: '-5px' }}
        />
      </div>
    ))}
  </div>
);
