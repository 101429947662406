import * as React from 'react';
import cn from 'classnames';
import { gql } from 'urql';
import { useStoreon } from 'hooks';
import FlagOrangeIcon from './icons/geoFlagOrange.svg';
import EditIcon from './icons/Edit';

type Option = {
  id: number;
  name: string;
};

type SelectProps = {
  defaultValue?: number;
  options: readonly Option[];
  onChange: (val: number) => void;
};

const Select = ({ options, defaultValue, onChange }: SelectProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const currentValue = options.find((o) => o.id === defaultValue);

  React.useEffect(() => {
    const fn = () => {
      if (open) {
        setOpen(false);
      }
    };

    document.addEventListener('click', fn);

    return () => document.removeEventListener('click', fn);
  }, [open]);

  const handleOpen = () => setOpen((o) => !o);
  const handleValueChange = (value: number) => {
    onChange(value);
    setOpen(false);
  };

  return (
    <div className="w-full">
      <div ref={ref} className="relative  w-full max-w-[200px]">
        <button
          type="submit"
          onClick={handleOpen}
          className="flex justify-center items-center bg-red-500 h-8 rounded-2xl px-5 text-sm uppercase text-black font-medium tracking-wider duration-200 hover:opacity-70 lg:px-0 lg:w-8"
        >
          <span className="lg:hidden">изменить</span>
          <div className="lg:block hidden">
            <EditIcon size={25} />
          </div>
        </button>
        {open && (
          <div
            style={{ borderRadius: 4, boxShadow: '0 0 0 1px rgb(68 88 112 / 11%)' }}
            className="absolute top-full right-0 z-10 min-w-[160px] rounded-md bg-white"
          >
            {options.map((o) => (
              <button
                type="button"
                onClick={() => handleValueChange(o.id)}
                className={cn(
                  'text-sm min-h-[40px] px-4 w-full border-transparent transition-colors hover:bg-[#f6f7f9] cursor-pointer',
                  currentValue?.id === o.id && 'font-medium bg-[#f6f7f9]',
                )}
                key={o.id}
              >
                {o.name}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

type ItemWrapperProps = {
  title?: string;
  actions: React.ReactNode;
};

const CheckoutItemWrapper: React.FC<ItemWrapperProps> = ({ children, title, actions }) => (
  <div
    className="flex justify-between items-center border-b py-4 relative"
    style={{ borderColor: 'hsla(0,0%,59.2%,.2)' }}
  >
    <div className="flex items-center">
      <div className="flex flex-none justify-center items-center w-12 h-12 bg-orange-100 rounded-full mr-5 lg:w-9 lg:h-9 lg:mr-3.5">
        <img src={FlagOrangeIcon} alt="clock" className="w-6 h-6 lg:h-4 lg:w-4" />
      </div>
      <div>
        <p className="text-gray-50 lg:text-xs lg:py-0.5">{title}</p>
        {children}
      </div>
    </div>
    <div>{actions}</div>
  </div>
);

type Props = {
  value: number;
  onChange: (val: number) => void;
};

export const CheckoutRegion = ({ value, onChange }: Props) => {
  const { appData } = useStoreon('appData');
  const {regions} = appData.viewer;
  const currentRegion = regions.find((i) => value === i.id);

  return (
    <CheckoutItemWrapper title="Регион" actions={<Select options={regions} onChange={onChange} defaultValue={value} />}>
      <p className="lg:text-sm lg:py-0.5">{currentRegion?.name || 'Не определен'}</p>
    </CheckoutItemWrapper>
  );
};

gql`
  fragment CheckoutRegion_data on Viewer {
    id
    currentRegion {
      id
    }
    regions {
      id
      name
    }
  }
`;
