import * as React from 'react';
import { gql } from '@urql/core';
import { navigate } from 'gatsby';
import { CatalogLayout } from 'components/CatalogLayout';
import { useStoreon, useDryOrder, getExtraDryOrderData } from 'hooks';
import { OrderPaymentMethod, useBasicCheckoutMutation } from 'generated/graphql';
import { captureException, maxTime } from 'utils';
import { BackButton } from 'components/BackButton';
import { Alert } from 'components/Alert';

import { Price } from 'components/Price';
import { CheckoutDeliveryAddress } from 'components/CheckoutDeliveryAddress';
import { CheckoutPromocode } from 'components/CheckoutPromocode';
import { CheckoutDeliveryTime } from 'components/CheckoutDeliveryTime';
import { CheckoutChangeProfile } from 'components/CheckoutChangeProfile';
import { useForm, Controller } from 'react-hook-form';
import { CheckoutStage } from 'components/CheckoutStage';
import { PageError } from 'components/PageError';
import { Loader } from 'components/Loader';
import { AuthRequired } from 'components/AuthRequired';
import { ProgressButton } from 'components/ProgressButton';
import { Switch } from 'components/Switch';
import { DontUseAutoPayment } from 'components/DontUseAutoPayment';
import { purchase, userClickButtonOrderConfirm } from 'analytics';
import EditIcon from 'components/icons/Edit';
import { CheckoutRegion } from 'components/CheckoutRegion';

const paymentMethodsLabels = {
  [OrderPaymentMethod.Online]: {
    name: OrderPaymentMethod.Online,
    label: 'Онлайн',
  },
  [OrderPaymentMethod.ByCard]: {
    name: OrderPaymentMethod.ByCard,
    label: 'Картой курьеру',
  },
  [OrderPaymentMethod.ByCash]: {
    name: OrderPaymentMethod.ByCard,
    label: 'Наличными курьеру',
  },
  [OrderPaymentMethod.Deposit]: {
    name: OrderPaymentMethod.ByCard,
    label: 'Депозит',
  },
};

const CheckoutPage = () => {
  const { handleSubmit, control } = useForm();
  const {
    dispatch,
    appData,
    paymentMethod: newPaymentMethod,
    deliveryAt: selectedDeliveryAt,
  } = useStoreon('paymentMethod', 'deliveryAt', 'appData');
  const { user } = appData.viewer;
  const regionId = appData.viewer.currentRegion?.id;
  const nearestDelivery = appData.viewer.nearestDeliveryInterval?.value || null;

  const [queryRes, refetchQuery, variables] = useDryOrder();
  const extra = getExtraDryOrderData(queryRes);
  const [mutationState, mutate] = useBasicCheckoutMutation();

  const deliveryAt = maxTime(selectedDeliveryAt, nearestDelivery);

  if (queryRes.error) {
    const retry = () => refetchQuery({ requestPolicy: 'network-only' });
    return (
      <CatalogLayout>
        <PageError error={queryRes.error} retry={retry} className="justify-center" />
      </CatalogLayout>
    );
  }

  if (queryRes.fetching || !queryRes.data) {
    return (
      <CatalogLayout>
        <Loader className="flex justify-center" />
      </CatalogLayout>
    );
  }

  if (!user) {
    return (
      <CatalogLayout>
        <AuthRequired />
      </CatalogLayout>
    );
  }

  const { dryOrder } = queryRes.data.viewer;
  const paymentMethod = newPaymentMethod || user.lastOrder?.paymentMethod || OrderPaymentMethod.Online;

  if (dryOrder.__typename !== 'PlaceOrderPayload') {
    if (dryOrder.__typename === 'ErrorPayload') {
      return <CatalogLayout>{dryOrder.message}</CatalogLayout>;
    }

    captureException(new Error(`unexpected __typename: ${JSON.stringify(dryOrder)}`));
    return null;
  }

  const products = dryOrder.order.items.map((i) => ({
    id: i.product.id,
    name: i.product.name,
    price: i.product.price,
    quantity: i.quantity,
  }));

  const placeOrder = async (values: {
    ecoPack: boolean;
    regionId: number;
    asap: boolean;
    withoutOverweight: boolean;
  }) => {
    if (!mutationState.fetching) {
      try {
        let { addressId } = variables.input;

        if (!addressId && user.addresses.length === 1) {
          addressId = user.addresses[0].id;
        }

        const res = await mutate({
          ...variables.input,
          ...values,
          paymentMethod,
          deliveryAt,
          addressId,
        });

        if (res.__typename === 'PlaceOrderPayload') {
          purchase({
            id: res.order.id,
            totalSum: res.order.totalSum,
            products,
          });
          userClickButtonOrderConfirm();
          navigate(`/neworder?id=${res.order.id}`, { replace: true });
          dispatch('clearCart', null);

          if (res.resetPromoCode) {
            dispatch('setPromoCode', null);
          }
        }
      } catch (err) {
        // eslint-disable-next-line
        alert(err.message);
      }
    }
  };
  return (
    <CatalogLayout>
      <div className="flex items-center py-2.5">
        <BackButton />
        <div>
          <p className="text-left text-2xl font-medium">Подтверждение заказа</p>
          <div className="text-left flex items-baseline">
            <p className="mr-1">Итоговая сумма заказа:</p>
            <Price value={dryOrder.order.totalSum} className="text-lg" />
          </div>
        </div>
      </div>
      <CheckoutStage step={2} />
      <div>
        <Controller
          name="regionId"
          control={control}
          render={({ field }) => <CheckoutRegion value={field.value} onChange={field.onChange} />}
          defaultValue={regionId}
        />

        <CheckoutDeliveryAddress data={user} />
        <CheckoutDeliveryTime />
        <CheckoutPromocode />
        <CheckoutChangeProfile />
      </div>

      <form onSubmit={handleSubmit(placeOrder)}>
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <textarea
              {...field}
              placeholder="Введите комментарий (по желанию)"
              className="w-full h-20 text-black bg-red-200 border border-red-200 rounded-[10px] py-2 px-4 resize-none transition-colors duration-300 ease-in-out placeholder-[#757575] focus:border-[#8bced4] mt-4"
            />
          )}
          defaultValue={variables.input.comment}
        />
        <div style={{ borderColor: 'hsla(0,0%,59.2%,.2)' }} className="border-b py-5 flex items-center justify-between">
          <p className="pl-2">
            Способ оплаты: <span className="font-bold">{paymentMethodsLabels[paymentMethod].label}</span>
          </p>

          <button
            type="button"
            onClick={() => dispatch('setModal', { type: 'changePaymentMethod' })}
            className="flex justify-center items-center bg-red-500 h-8 rounded-2xl px-5 text-sm uppercase text-black font-medium tracking-wider duration-200 hover:opacity-70 lg:px-0 lg:w-8"
          >
            <span className="lg:hidden">изменить</span>
            <div className="lg:block hidden">
              <EditIcon size={25} />
            </div>
          </button>
        </div>
        {paymentMethod === OrderPaymentMethod.Online && <DontUseAutoPayment user={user} />}
        <div
          className="flex items-center py-5 lg:justify-between border-b"
          style={{ borderColor: 'hsla(0,0%,59.2%,.2)' }}
        >
          <div className="lg:order-2 flex items-center">
            <Controller
              name="asap"
              control={control}
              render={({ field }) => <Switch value={field.value} onChange={field.onChange} />}
              defaultValue={variables.input.asap}
            />
          </div>
          <div className="ml-3 lg:order-1">
            Доставить как можно скорее
            <div className="text-sm">Мы постараемся доставить Ваш заказ как можно раньше</div>
          </div>
        </div>
        <div
          className="flex items-center py-5 lg:justify-between border-b"
          style={{ borderColor: 'hsla(0,0%,59.2%,.2)' }}
        >
          <div className="lg:order-2 flex items-center">
            <Controller
              name="withoutOverweight"
              control={control}
              render={({ field }) => <Switch value={field.value} onChange={field.onChange} />}
              defaultValue={variables.input.withoutOverweight}
            />
          </div>
          <div className="ml-3 lg:order-1">
            Без перевесов
            <div className="text-sm">Фактический вес продукта не будет превышать вес, указанный в заказе</div>
          </div>
        </div>
        <div
          className="flex items-center py-5 lg:justify-between border-b"
          style={{ borderColor: 'hsla(0,0%,59.2%,.2)' }}
        >
          <div className="lg:order-2 flex items-center">
            <Controller
              name="ecoPack"
              control={control}
              render={({ field }) => <Switch value={field.value} onChange={field.onChange} />}
              defaultValue={variables.input.ecoPack}
            />
          </div>
          <div className="ml-3 lg:order-1">
            Упаковка без пластика
            <div className="text-sm">
              По возможности мы упакуем продукты в тару из экологичных материалов — бумаги, картона и стекла
            </div>
          </div>
        </div>
        <div>
          {dryOrder.alerts.map((a) => (
            <Alert key={a.message} {...a} />
          ))}
        </div>

        <div className="flex items-center justify-between py-3 mt-3 lg:flex-col lg:text-center">
          <div>
            <div className="flex items-baseline lg:justify-center">
              <p className="mr-1 lg:text-sm">Заказ:</p>
              <Price value={dryOrder.order.itemSum} />
            </div>
            <div className="flex items-baseline lg:justify-center">
              <p className="mr-1 lg:text-sm">Доставка:</p>
              <Price value={dryOrder.order.deliveryCost} allowFree />
            </div>
            <div className="flex items-baseline text-2xl font-medium lg:mb-1.5 lg:justify-center">
              <p className="mr-1 lg:text-lg">Итого:</p>
              <Price value={dryOrder.order.totalSum} className="text-2xl" />
            </div>
          </div>
          <ProgressButton
            type="submit"
            isProgress={mutationState.fetching}
            styles="btn btn-orange"
            disabled={!extra.canPlace}
          >
            Подтвердить заказ
          </ProgressButton>
        </div>
      </form>
    </CatalogLayout>
  );
};

gql`
  mutation CheckoutMutation($input: PlaceOrderInput!) {
    result: placeOrder(input: $input) {
      ... on PlaceOrderPayload {
        order {
          id
          totalSum
        }

        alerts {
          type
          message
        }

        resetPromoCode
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;

export default CheckoutPage;
