import * as React from 'react';
import { graphql } from 'gatsby';
import { CheckoutDeliveryAddress_Data } from 'generated/graphql';
import { useStoreon } from 'hooks';
import FlagOrangeIcon from './icons/geoFlagOrange.svg';
import { Address } from './Address';
import EditIcon from './icons/Edit';

type Props = {
  readonly data: CheckoutDeliveryAddress_Data;
};

const getLabel = (data: CheckoutDeliveryAddress_Data) => {
  if (data.addresses.length === 0) {
    return 'Добавить адрес';
  }

  return data.activeAddress ? <Address data={data.activeAddress} /> : 'Выбрать адрес';
};

export const CheckoutDeliveryAddress = ({ data }: Props) => {
  const { dispatch } = useStoreon();
  const selectAddress = () => dispatch('setModal', { type: 'changeActiveAddress' });

  return (
    <div className="border-b py-4" style={{ borderColor: 'hsla(0,0%,59.2%,.2)' }}>
      <div className="flex justify-between items-center relative">
        <div className="flex items-center">
          <div className="flex flex-none justify-center items-center w-12 h-12 bg-orange-100 rounded-full mr-5 lg:w-9 lg:h-9 lg:mr-3.5">
            <img src={FlagOrangeIcon} alt="clock" className="w-6 h-6 lg:h-4 lg:w-4" />
          </div>
          <div>
            <p className="text-gray-50 lg:text-xs lg:py-0.5">Адрес доставки</p>
            <button
              type="button"
              onClick={selectAddress}
              className="text-left h-6 overflow-hidden overflow-ellipsis lg:text-sm lg:py-0.5"
              style={{ maxHeight: '20px' }}
            >
              {getLabel(data)}
            </button>
          </div>
        </div>
        <button
          type="submit"
          onClick={selectAddress}
          className="flex flex-none justify-center items-center bg-red-500 h-8 rounded-2xl px-5 text-sm uppercase text-black font-medium tracking-wider duration-200 hover:opacity-70 lg:px-0 lg:w-8"
        >
          <span className="lg:hidden">изменить</span>
          <div className="lg:block hidden">
            <EditIcon size={25} />
          </div>
        </button>
        <button
          type="submit"
          aria-label="label"
          onClick={selectAddress}
          className="hidden absolute w-full h-full lg:block"
        />
      </div>
    </div>
  );
};

export const fragments = graphql`
  fragment CheckoutDeliveryAddress_data on User {
    activeAddress {
      id
      ...Address_data
    }
    addresses {
      id
    }
  }
`;
